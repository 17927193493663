<script setup lang="ts">
import Generic from '~/layouts/generic.vue'
import Header from '~/components/Layout/Header.vue'
import Banner from '~/components/Layout/Banner.vue'
import LazyLeadDialog from '~/components/Lead/LeadDialog.vue';
import LazyNewsletterDialog from '~/components/Newsletter/NewsletterDialog.vue';
import { useBannerStore } from '~/stores/useBannerStore';

const { isBannerVisible } = storeToRefs(useBannerStore())
const { loadBrandList } = useBrandStore()
const { loadDisclaimer } = useDisclaimerStore()

useAsyncData('brand-list', async () => loadBrandList(), { server: false })
useAsyncData('disclaimer', async () => loadDisclaimer(), { server: false })
</script>

<template>
  <Generic>
    <Header />
    <Banner v-if="isBannerVisible" />
    <LazyLeadDialog />
    <LazyNewsletterDialog />
    <Toast position="bottom-center" group="bc" />
    <div class="mt-20 w-full bg-white md:mt-28">
      <router-view />
    </div>
  </Generic>
</template>